import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import WelcomeScreen from './screens/WelcomeScreen';
import ZodiacScreen from './screens/ZodiacScreen';
import ColorScreen from './screens/ColorScreen';
import NumberScreen from './screens/NumberScreen';
import LandingScreen from './screens/LandingScreen';
import AuthContainer from './screens/AuthContainer';
import AccountScreen from './screens/AccountScreen';
import GameSelectionScreen from './screens/GameSelectionScreen';
import TransactionsScreen from './screens/TransactionsScreen';

function App() {
  // return (<Router>
  //   <Routes>
  //     <Route path="/" element={<LandingScreen />} />
  //     <Route path="/auth" element={<AuthContainer />} />
  //     <Route path="/game-selection" element={<GameSelectionScreen />} />
  //     <Route path="/zodiac" element={<ZodiacScreen />} />
  //     <Route path="/color" element={<ColorScreen />} />
  //     <Route path="/number" element={<NumberScreen />} />
  //     <Route path="/account" element={<AccountScreen />} />
  //     <Route path="/transactions" element={<TransactionsScreen />} />
  //     <Route path="/welcome" element={<WelcomeScreen />} />
  //   </Routes>
  // </Router>);
  return (<h5>Server is upgrading, please check back later!</h5>)
}

export default App;